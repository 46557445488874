import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import Typography from '@material-ui/core/Typography'
import NotFoundGif from './404.gif'

class NotFound extends Component {
  //   componentDidMount() {
  //     this.props.dispatch(setVisibility(false))
  //   }
  //   componentWillUnmount() {
  //     this.props.dispatch(setVisibility(true))
  //   }
  render() {
    const { classes } = this.props
    return (
      <div style={{ margin: '16px' }}>
        <section className={classes.notFound}>
          <div className={classes.leftSection}>
            <div className={classes.text}>
              <Typography variant='display3' className={classes.display}>
                Looks like you got lost
              </Typography>
              <Typography variant='subtitle1'>
                Sorry about that, check the URL and make sure you didn't make a
                mistake, or refresh and try again
              </Typography>
            </div>
          </div>
          <div className={classes.rightSection}>
            <img
              src={NotFoundGif}
              alt='Not Found'
              className={classes.notFoundGif}
            />
          </div>
        </section>
      </div>
    )
  }
}

export default withStyles(styles)(NotFound)
