import React, { Component } from 'react'
import { TextField, Button, Typography, Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { login, sendPin } from '../../db'
import logo from '../../assets/brand.png'
class Login extends Component {
  state = {
    phone: ''
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      window.location.href = '/'
    }
  }

  submit = pin => {
    this.props
      .dispatch(login({ phone: this.state.phone, pin }))
      .then(res => {
        localStorage.setItem('token', res.token)
        localStorage.setItem('user_id', res.id)
        window.location.href = '/'
      })
      .catch(err => console.log(err))
  }

  openPin = () => {
    this.props
      .dispatch(sendPin({ phone: '+1' + this.state.phone }))
      .catch(err =>
        this.props.dispatch({
          type: 'PIN_DIALOG',
          payload: { open: false }
        })
      )
    this.props.dispatch({
      type: 'PIN_DIALOG',
      payload: { open: true, callback: this.callback }
    })
  }

  callback = pin => {
    if (pin.length === 4) {
      this.submit(pin)
    } else {
      setTimeout(() => {
        this.openPin()
      }, 500)
    }
  }

  render() {
    const { classes } = this.props
    const { phone } = this.state
    return (
      <div style={{ textAlign: 'center' }}>
        <div className={classes.header}>
          <img src={logo} style={{ width: '200px' }} />
          <div className={classes.triangle} />
        </div>
        <div className={classes.wrapper}>
          <Typography variant='h3' color='secondary'>
            Login
          </Typography>
          <br />
          <Typography variant='caption' color='secondary'>
            Enter your mobile phone to join the lunch club
          </Typography>
        </div>
        <div style={{ margin: '16px', marginBottom: '200px' }}>
          <TextField
            label='Mobile'
            fullWidth
            value={phone}
            onChange={e => this.setState({ phone: e.target.value })}
          />
        </div>
        <div className={classes.bottom}>
          <div className={classes.button2} onClick={this.openPin}>
            <Typography style={{ color: 'white' }}>JOIN THE CLUB</Typography>{' '}
            <KeyboardArrowRight style={{ color: 'white' }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(withStyles(styles)(Login))
