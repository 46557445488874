import React, { Component } from 'react'
import { Button, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { getRestaurants, getUser } from '../../db'

class PickFavorites extends Component {
  state = {
    picks: [],
    restaurants: [],
    favorites: []
  }

  componentDidMount() {
    this.props.dispatch(getUser(localStorage.getItem('user_id'))).then(res => {
      this.setState({ restaurants: res.data.restaurants })
      const favorites = []
      res.data.favorites.forEach(x => {
        favorites.push({ ...x, order_data: JSON.parse(x.order_data) })
      })
      this.setState({ favorites })
    })
  }

  render() {
    const { classes } = this.props
    const { picks, restaurants, favorites } = this.state
    console.log(picks, restaurants, favorites)
    return (
      <div style={{ textAlign: 'center' }}>
        <div className={classes.header}>
          <div>
            <Typography variant='h2' style={{ color: 'white' }}>
              1
            </Typography>
            <br />
            <Typography variant='h5' style={{ color: 'white' }}>
              YOUR FAVORITE <br /> ORDERS
            </Typography>
          </div>
          <div className={classes.triangle} />
        </div>
        <div className={classes.wrapper}>
          <Typography variant='h5' color='secondary'>
            Pick a few favorite <br /> meals.
          </Typography>
        </div>
        <div
          style={{ textAlign: 'left', margin: '16px', marginBottom: '80px' }}
        >
          {restaurants.map((x, i) => {
            return (
              <div
                key={i}
                style={{
                  marginBottom: '16px'
                }}
                onClick={e => {
                  localStorage.setItem('back_to_pick', true)
                  window.location.href = '/restaurants/' + x.chow_now_id
                }}
              >
                <Typography
                  variant='h6'
                  style={{
                    color: '#FF04A3'
                  }}
                >
                  {x.name}
                </Typography>
                <div style={{ maxWidth: '200px' }}>
                  <Typography variant='caption' color='secondary'>
                    {x.address.formatted_address}
                  </Typography>
                </div>
                <Typography
                  variant='subtitle1'
                  color='secondary'
                  style={{ textDecoration: 'underline' }}
                >
                  {
                    favorites.filter(
                      y => y.order_data.restaurant_id === x.chow_now_id
                    ).length
                  }{' '}
                  Favorites
                </Typography>
              </div>
            )
          })}
        </div>
        <div className={classes.bottom}>
          <Button
            size='large'
            className={classes.button}
            onClick={e => (window.location.href = '/')}
          >
            Choose Later
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='secondary'
            size='large'
            onClick={e => (window.location.href = '/')}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(withStyles(styles)(PickFavorites))
