const styles = theme => ({
  header: {
    height: '250px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 32
  },
  triangle: {
    position: 'absolute',
    bottom: 0,
    left: 16,
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '20px solid ' + theme.palette.primary.main,
    transform: 'translate(0px, 20px)'
  },
  wrapper: {
    textAlign: 'left',
    marginLeft: '16px'
  },
  button: {
    marginRight: 16,
    marginLeft: 16,
    marginTop: 8,
    marginBottom: 8
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.15)'
  }
})

export default styles
