import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { LinearProgress } from '@material-ui/core'
import { authRefresh } from '../db'

class ProtectedRoute extends React.Component {
  state = {
    show: false
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    if (token) {
      //   this.props
      //     .dispatch(authRefresh(token))
      //     .then(res => {
      //       this.setState({ show: true })
      //     })
      //     .catch(err => {
      //       window.location.href = '/login'
      //     })
      this.setState({ show: true })
    } else {
      window.location.href = '/login'
    }
  }

  render() {
    const { component: C, ...rest } = this.props
    const { show } = this.state
    return (
      <div>
        {show ? (
          <Route {...rest} render={props => <C {...props} />} />
        ) : (
          <LinearProgress color='secondary' />
        )}
      </div>
    )
  }
}

export default ProtectedRoute

// export default () => (

// )
