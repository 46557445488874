import { combineReducers } from 'redux'
// import {
//   values,
//   remove,
//   insertAll,
//   insert,
//   indexOf,
//   findIndex,
//   propEq
// } from 'ramda'

const profile = (state = false, action) => {
  return action.type === 'SET_PROFILE' ? action.payload : state
}

const pinDialog = (state = { open: false }, action) => {
  return action.type === 'PIN_DIALOG' ? action.payload : state
}

export default combineReducers({
  profile,
  pinDialog
})
