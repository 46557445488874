const button = {
  minWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px'
}

const styles = theme => ({
  header: {
    height: '250px',
    backgroundColor: '#f3f3f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 64
  },
  triangle: {
    position: 'absolute',
    bottom: 0,
    left: 16,
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '20px solid #f3f3f3',
    transform: 'translate(0px, 20px)'
  },
  wrapper: {
    textAlign: 'left',
    marginLeft: '16px'
  },
  button1: {
    ...button
  },
  button2: {
    ...button,
    backgroundColor: theme.palette.secondary.main
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    width: '100%',
    borderTop: '1px solid ' + theme.palette.secondary.main
  }
})

export default styles
