import React from 'react'
import Header from './components/header'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Dashboard from './pages/Dashboard'
import Locations from './pages/Locations'
import Profile from './pages/Profile'
import Favorites from './pages/Favorites'
import Restaurant from './pages/Restaurant'
import Resturant from './pages/Restaurant'
import Login from './pages/Login'
import NotFound from './pages/NotFound/index'
import PickRestaurant from './pages/PickRestaurant'
import PickLocations from './pages/PickLocations'
import PickFavorites from './pages/PickFavorites'
import MagicLink from './pages/MagicLink'

import ProtectedRoute from './components/protectedRoute'
import PinDialog from './components/PinDialog'
class App extends React.Component {
  render() {
    return (
      <div className='App'>
        {window.location.pathname !== '/login' &&
          window.location.pathname.search('/pick/') !== 0 && <Header />}
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <ProtectedRoute exact path='/locations' component={Locations} />
            <ProtectedRoute
              path='/restaurants/:restaurant_id'
              component={Restaurant}
            />
            <ProtectedRoute exact path='/resturant' component={Resturant} />
            <ProtectedRoute exact path='/profile' component={Profile} />
            <ProtectedRoute exact path='/favorites' component={Favorites} />
            <ProtectedRoute
              exact
              path='/pick/restaurants'
              component={PickRestaurant}
            />
            <ProtectedRoute
              exact
              path='/pick/locations'
              component={PickLocations}
            />
            <ProtectedRoute
              exact
              path='/pick/favorites'
              component={PickFavorites}
            />
            <Route exact path='/login' component={Login} />
            <Route path='/m/:magic_link' component={MagicLink} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
        <PinDialog />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(App)
