import React, { Component } from 'react'
import {
  TextField,
  Button,
  Typography,
  Icon,
  Popover,
  Divider
} from '@material-ui/core'
import { connect } from 'react-redux'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { getUser, sendLocations } from '../../db'
import AutoComplete from '../../components/AutoComplete'
import Check from '@material-ui/icons/Check'

class PickLocations extends Component {
  state = {
    locations: [],
    anchorEl: null,
    currentLocations: []
  }

  componentDidMount() {
    this.props.dispatch(getUser(localStorage.getItem('user_id'))).then(res => {
      this.setState({ currentLocations: res.data.locations })
    })
  }

  submit = pin => {
    if (this.state.locations.length > 0) {
      this.props
        .dispatch(sendLocations(this.state.locations))
        .then(res => {
          window.location.href = '/pick/favorites'
        })
        .catch(console.log)
    } else {
      window.location.href = '/pick/favorites'
    }
  }

  render() {
    const { classes } = this.props
    const { locations, anchorEl, currentLocations } = this.state
    return (
      <div style={{ textAlign: 'center' }}>
        <div className={classes.header}>
          <div>
            <Typography variant='h2' style={{ color: 'white' }}>
              1
            </Typography>
            <br />
            <Typography variant='h5' style={{ color: 'white' }}>
              SET YOUR <br /> LOCATIONS
            </Typography>
          </div>
          <div className={classes.triangle} />
        </div>
        <div className={classes.wrapper}>
          <Typography variant='h5' color='secondary'>
            Tell us a few locations <br /> you order from.
          </Typography>
        </div>
        <div style={{ marginBottom: '200px' }}>
          <div style={{ textAlign: 'left', margin: '16px' }}>
            <AutoComplete
              fullWidth
              onSelect={e => {
                if (e.street_number) {
                  const newLocations = locations
                  newLocations.push(e)
                  this.setState({
                    locations: newLocations,
                    anchorEl: document.getElementById('autocomplete')
                  })
                } else {
                  console.log('invalid address')
                }
                document.getElementById('autocomplete').value = ''
              }}
            />
          </div>
          <div
            style={{
              textAlign: 'left',
              marginLeft: '16px'
            }}
          >
            {locations.concat(currentLocations).map((x, i) => {
              return (
                <div key={i} style={{ marginBottom: '16px' }}>
                  <Typography
                    variant='h6'
                    style={{ textDecoration: 'underline' }}
                    color='secondary'
                  >
                    {x.name ? x.name : 'UNNAMED'}
                  </Typography>
                  <Typography variant='caption' style={{ color: 'grey' }}>
                    {x.street_number + ' ' + x.route + '.'} <br />
                    {x.locality +
                      ' ' +
                      x.administrative_area_level_1 +
                      ' ' +
                      x.postal_code}
                  </Typography>
                </div>
              )
            })}
          </div>
        </div>
        <div className={classes.bottom}>
          <Button
            size='large'
            className={classes.button}
            onClick={e => (window.location.href = '/pick/favorites')}
          >
            Choose Later
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='secondary'
            size='large'
            onClick={e => {
              this.submit()
            }}
          >
            Next
          </Button>
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <div>
            <div style={{ display: 'flex' }}>
              <TextField
                placeholder='Location Name'
                style={{ margin: '8px', color: '#ff04a3' }}
                id='location-name'
                InputProps={{
                  disableUnderline: true,
                  style: { color: '#ff04a3' }
                }}
              />
              <div
                onClick={e => {
                  const newLocations = locations
                  newLocations[
                    newLocations.length - 1
                  ].name = document.getElementById('location-name').value
                  this.setState({
                    anchorEl: null,
                    locations: newLocations
                  })
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#ff04a3'
                }}
              >
                <Check
                  style={{ fontSize: '30px', color: 'white', padding: '8px' }}
                />
              </div>
            </div>
            <Divider />
            <div style={{ margin: '16px' }}>
              <Typography
                variant='caption'
                color='secondary'
                style={{ fontStyle: 'italics' }}
              >
                name this location
              </Typography>
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(withStyles(styles)(PickLocations))
