import React, { Component } from 'react'
import { connect } from 'react-redux'
import { magicLink } from '../../db'
import { LinearProgress } from '@material-ui/core'

class MagicLink extends Component {
  componentDidMount() {
    this.props
      .dispatch(magicLink(this.props.match.params.magic_link))
      .then(res => {
        if (res.msg && res.msg === 'User does not exist') {
          localStorage.clear()
          window.location.href = '/login'
        } else {
          localStorage.setItem('user_id', res.id)
          localStorage.setItem('token', res.token)
          window.location.href = '/'
        }
      })
      .catch(console.log)
  }
  render() {
    console.log(this)
    return <LinearProgress />
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(MagicLink)
