import React from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'

var autocomplete
var componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name'
}

var google = window.google

function initAutocomplete(all, callback) {
  autocomplete = new google.maps.places.Autocomplete(
    /** @type {!HTMLInputElement} */ (document.getElementById('autocomplete')),
    { types: ['geocode'] }
  )
  autocomplete.addListener('place_changed', e => {
    fillInAddress(callback)
    all.setState({ clean: true })
    document.getElementById('autocomplete').focus()
  })
}

function fillInAddress(callback) {
  var place = autocomplete.getPlace(),
    callbackObj = {}
  if (document.getElementById('cityLat') && place.geometry) {
    document.getElementById('cityLat').value = place.geometry.location.lat()
    document.getElementById('cityLng').value = place.geometry.location.lng()
    callbackObj.lat = place.geometry.location.lat()
    callbackObj.lng = place.geometry.location.lng()
  }
  for (var component in componentForm) {
    document.getElementById(component).value = ''
    document.getElementById(component).disabled = false
  }
  for (var i = 0; i < place.address_components.length; i++) {
    var addressType = place.address_components[i].types[0]
    if (componentForm[addressType]) {
      var val = place.address_components[i][componentForm[addressType]]
      callbackObj[addressType] = val
      document.getElementById(addressType).value = val
    }
  }
  if (Object.keys(callbackObj).length > 0) {
    callback(callbackObj)
  }
}

function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      var circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy
      })
      autocomplete.setBounds(circle.getBounds())
    })
  }
}

class Autocomplete extends React.Component {
  componentDidMount() {
    initAutocomplete(this, this.props.onSelect)
    document.getElementById('autocomplete').placeholder = ' '
  }

  state = {
    clean: false,
    chekced: false
  }

  render() {
    const props = this.props
    var obj = {
      street_number: '',
      route: '',
      city: '',
      state: '',
      zip: '',
      address: '',
      lat: '',
      lng: ''
    }
    var onChange = () => ''
    var onBlur = () => ''
    if (props.obj) {
      obj = props.obj
      if (!this.state.clean && !this.state.checked) {
        this.setState({ clean: true })
        this.setState({ checked: true })
      }
    }
    if (props.onChange) {
      onChange = props.onChange
    }
    if (props.onBlur) {
      onBlur = props.onBlur
    }
    this.props.dispatch({
      type: 'SET_ADDRESS_READY',
      payload: this.state.clean
    })
    console.log('here')
    return (
      <div>
        <TextField
          label='Enter Address'
          id='autocomplete'
          defaultValue={obj.address}
          onClick={geolocate()}
          type='text'
          next={props.next}
          desc='Enter your Address'
          onBlur={() => {
            onBlur()
          }}
          onChange={e => {
            onChange(e)
            this.setState({ clean: false })
          }}
          fullWidth
        />
        <input
          id='street_number'
          hidden='true'
          defaultValue={obj.street_number}
        />
        <input id='route' hidden='true' defaultValue={obj.route} />
        <input id='locality' hidden='true' defaultValue={obj.city} />
        <input
          id='administrative_area_level_1'
          hidden='true'
          defaultValue={obj.state}
        />
        <input id='postal_code' hidden='true' defaultValue={obj.zip} />
        <input id='country' hidden='true' defaultValue='US' />
        <input
          type='hidden'
          id='cityLat'
          name='cityLat'
          defaultValue={obj.lat}
        />
        <input
          type='hidden'
          id='cityLng'
          name='cityLng'
          defaultValue={obj.lng}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { dispatch: state.dispatch }
}

const connector = connect(mapStateToProps)

export default connector(Autocomplete)
