import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogContent, Typography, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import './test.css'

class PinDialog extends Component {
  close = () => {
    this.props.dispatch({ type: 'PIN_DIALOG', payload: { open: false } })
  }

  handleChange = e => {
    const val = e.target.id.match(/(\d)/)[1]
    const elem = document.getElementById('pin-' + (Number(val) + 1))
    if (elem) {
      elem.focus()
    } else {
      const pin =
        document.getElementById('pin-1').value +
        document.getElementById('pin-2').value +
        document.getElementById('pin-3').value +
        document.getElementById('pin-4').value
      this.props.callback(pin)
      this.close()
    }
  }

  render() {
    const { open, classes } = this.props
    return (
      <Dialog open={open} onClose={this.close}>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant='subtitle1'>ENTER YOUR PIN</Typography>
          <Typography variant='caption'>
            We just texted you a pin number to <br /> help you login, enter it
            here.
          </Typography>
        </DialogContent>
        <DialogContent className={classes.inputWrapper}>
          <TextField
            id='pin-1'
            placeholder='0'
            className={classes.input}
            onChange={this.handleChange}
          ></TextField>
          <TextField
            id='pin-2'
            placeholder='0'
            className={classes.input}
            onChange={this.handleChange}
          ></TextField>
          <TextField
            id='pin-3'
            placeholder='0'
            className={classes.input}
            onChange={this.handleChange}
          ></TextField>
          <TextField
            id='pin-4'
            placeholder='0'
            className={classes.input}
            onChange={this.handleChange}
          ></TextField>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.pinDialog
  }
}

const connector = connect(mapStateToProps)

export default connector(withStyles(styles)(PinDialog))
