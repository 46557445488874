import React, { Component } from 'react'
import {
  TextField,
  Button,
  Typography,
  Icon,
  LinearProgress
} from '@material-ui/core'
import { connect } from 'react-redux'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import Check from '@material-ui/icons/Check'
import { getUser, getRestaurants, setRestaurants } from '../../db'

// const restaurant = [
//   { name: "Jimmy John's", id: 1 },
//   { name: 'Sesame Burger', id: 2 },
//   { name: 'Chick-Fil-A', id: 3 },
//   { name: 'Coleman Public House', id: 5 },
//   { name: "Dominoe's Pizza", id: 46 },
//   { name: "Vickery's", id: 7 },
//   { name: 'La Hacienda', id: 8 },
//   { name: "Poogan's Smoke House", id: 9 }
// ]

class PickRestaurant extends Component {
  state = {
    picks: [],
    restaurant: []
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(e => {
        this.props
          .dispatch(getRestaurants())
          .then(res => {
            this.setState({ restaurant: res.restaurants })
          })
          .catch(console.log)
      })
    }
  }

  submit = () => {
    const user_id = localStorage.getItem('user_id')
    const picks = this.state.picks.map(x => {
      return {
        name: x.name,
        street_number: '',
        route: x.address.street_address1,
        city: x.address.city,
        state: x.address.state,
        zip_code: x.address.zip,
        lat: x.address.latitude,
        lng: x.address.longitude,
        chow_now_id: x.id,
        user_id
      }
    })
    if (picks.length > 0) {
      this.props
        .dispatch(setRestaurants(picks))
        .then(res => {
          window.location.href = '/pick/locations'
        })
        .catch(console.log)
    } else {
      window.location.href = '/pick/locations'
    }
  }

  render() {
    const { classes } = this.props
    const { picks, restaurant } = this.state
    console.log(this.state)
    return (
      <div style={{ textAlign: 'center' }}>
        <div className={classes.header}>
          <div>
            <Typography variant='h2' style={{ color: 'white' }}>
              1
            </Typography>
            <br />
            <Typography variant='h5' style={{ color: 'white' }}>
              SELECT <br /> RESTAURANT
            </Typography>
          </div>
          <div className={classes.triangle} />
        </div>
        <div className={classes.wrapper}>
          <Typography variant='h5' color='secondary'>
            Pick a few restaurants <br /> you order from.
          </Typography>
        </div>
        <div
          style={{ textAlign: 'left', margin: '16px', marginBottom: '80px' }}
        >
          {restaurant && restaurant.length > 0 ? (
            restaurant.map((x, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    marginBottom: '16px'
                  }}
                  onClick={e => {
                    if (picks.findIndex(y => y.id === x.id) >= 0) {
                      this.setState({ picks: picks.filter(y => y.id !== x.id) })
                    } else {
                      this.setState({ picks: picks.concat([x]) })
                    }
                  }}
                >
                  {picks.findIndex(y => y.id === x.id) >= 0 ? (
                    <Check
                      style={{
                        marginRight: '16px',
                        marginBottom: '8px',
                        color: '#FF04A3'
                      }}
                    />
                  ) : (
                    <RadioButtonUnchecked
                      style={{ marginRight: '16px', marginBottom: '8px' }}
                    />
                  )}
                  <Typography
                    variant='body1'
                    style={{
                      color:
                        picks.findIndex(y => y.id === x.id) >= 0
                          ? '#FF04A3'
                          : 'grey'
                    }}
                  >
                    {x.name}
                  </Typography>
                </div>
              )
            })
          ) : (
            <LinearProgress />
          )}
        </div>
        <div className={classes.bottom}>
          <Button
            size='large'
            className={classes.button}
            onClick={e => (window.location.href = '/pick/locations')}
          >
            Choose Later
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='secondary'
            size='large'
            onClick={this.submit}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(withStyles(styles)(PickRestaurant))
