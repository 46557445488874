import { createMuiTheme } from '@material-ui/core/styles'

const primary = {
  main: '#ff04a3',
  dark: '#c60074',
  light: '#ff5fd4'
}
const secondary = '#0F6270'
const error = '#FBAB7E'

export const pickersTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: primary.light,
        // color: "black"
        '&:hover': {
          backgroundColor: primary.light
        }
      }
    }
  },
  MuiPaper: {
    root: {
      maxWidth: 310
    }
  }
})

export default createMuiTheme({
  palette: {
    primary,
    secondary: {
      main: secondary
    },
    error: {
      main: error
    }
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(',')
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: [primary, '!important']
        }
      }
    },
    MuiInput: {
      underline: {
        '&$focused:after': {
          borderBottomColor: [primary, '!important']
        }
      }
    },
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: '0.65'
        }
      },
      containedSecondary: {
        boxShadow:
          '0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 2px 9px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.2)'
        // boxShadow: `0px 1px 1px 0px ${hexToRgbA(secondary,0.2)}, 0px 4px 8px 0px ${hexToRgbA(secondary,0.14)}, 0px 3px 5px 1px ${hexToRgbA(secondary,0.12)}`
      },
      containedPrimary: {
        color: '#fff',
        boxShadow:
          '0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 2px 9px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.2)'
        // boxShadow: `0px 1px 1px 0px ${hexToRgbA(primary,0.2)}, 0px 4px 8px 0px ${hexToRgbA(primary,0.14)}, 0px 3px 5px 1px ${hexToRgbA(primary,0.12)}`
      }
    },
    MuiCard: {
      root: {
        boxShadow:
          '0px 0px 0px -1px rgba(0, 0, 0, 0.1), 0px 2px 16px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.1)'
      }
    },
    MuiCardContent: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 16,
          paddingRight: 16
        }
      }
    },
    MuiAppBar: {
      positionStatic: {
        position: 'relative'
      },
      colorPrimary: {
        color: '#fff'
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0px 0px 0px -1px rgba(0, 0, 0, 0.1), 0px 1px 7px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1)'
      },
      elevation2: {
        boxShadow:
          '0px 0px 0px -1px rgba(0, 0, 0, 0.16), 0px 4px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.16)'
      }
    }
  }
})
