const styles = theme => ({
  input: {
    maxWidth: '30px'
  },
  inputWrapper: {
    justifyContent: 'space-evenly',
    display: 'flex',
    marginBottom: '20px'
  }
})

export default styles
