import React from 'react'
import { connect } from 'react-redux'
import { getUser } from '../../db'
import { Typography, Card, CardContent, Button } from '@material-ui/core'

const hasMagicLink = !true

class Dashboard extends React.Component {
  state = {
    favorites: []
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    if (token && token.length > 0) {
      this.props
        .dispatch(getUser(localStorage.getItem('user_id')))
        .then(res => {
          if (res.data.restaurants.length === 0) {
            window.location.href = '/pick/restaurants'
            return
          } else if (res.data.locations.length === 0) {
            window.location.href = '/pick/locations'
            return
          } else if (res.data.favorites.length === 0) {
            window.location.href = '/pick/favorites'
            return
          }
          this.setState({ restaurants: res.data.restaurants })
          const favorites = []
          res.data.favorites.forEach(x => {
            favorites.push({ ...x, order_data: JSON.parse(x.order_data) })
          })
          this.setState({ favorites })
        })
    } else {
      window.location.href = '/login'
    }
  }
  render() {
    const { favorites } = this.state
    console.log(this.state)
    return (
      <div style={{ margin: '16px' }}>
        {favorites.map((x, i) => {
          return (
            <Card key={i} style={{ marginBottom: '16px' }}>
              <CardContent
                style={{
                  marginBottom: '16px',
                  display: 'flex'
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <Typography
                    variant='h5'
                    style={{ marginBottom: '4px' }}
                    color='primary'
                  >
                    {x.name}
                  </Typography>
                  {x.order_data.items && x.order_data.items.length
                    ? x.order_data.items.map((item, ii) => {
                        return (
                          <React.Fragment key={ii}>
                            <Typography
                              variant='caption'
                              style={{ color: 'grey' }}
                            >
                              {item.name}{' '}
                              {`${item.size ? '(' + item.size + ')' : ''}`}
                            </Typography>
                            <br />
                          </React.Fragment>
                        )
                      })
                    : null}
                </div>
                <div style={{ position: 'relative' }}>
                  <Typography>
                    {x.order_data && x.order_data.items
                      ? '$' +
                        Number(
                          x.order_data.items.reduce((a, b) => a + b.price, 0)
                        ).toFixed(2)
                      : '$0.00'}
                  </Typography>
                  <br />
                  <div>
                    <Button variant='contained' color='primary' disabled>
                      Order Now
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(Dashboard)
