const makeUrl = path =>
  'https://staging.bidr.co/api/lunchclub/' +
  path +
  '?key=ce52728f580499be6d00e2acbe8b996f'

const options = body => {
  return {
    method: 'POST',
    body
  }
}

const getOptions = () => {
  return {
    headers: { Authorization: localStorage.getItem('token') },
    method: 'get'
  }
}

const makeFormData = item => {
  var form_data = new FormData()
  const keys = Object.keys(item)
  for (var key in keys) {
    form_data.append(keys[key], item[keys[key]])
  }
  return form_data
}

export const login = data => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(makeUrl('login'), options(makeFormData(data)))
      .then(res => res.json())
      .then(res => {
        localStorage.setItem('token', res.token)
        localStorage.setItem('user_id', res.id)
        return fetch(
          makeUrl('users/' + localStorage.getItem('user_id')),
          getOptions()
        )
      })
      .then(res => res.json())
      .then(res => {
        if (res.data) {
          const user = res.data
          if (!user.resturants) {
            return (window.location.href = '/pick/restaurants')
          } else if (!user.locations) {
            return (window.location.href = '/pick/locations')
          } else if (!user.favorites) {
            return (window.location.href = '/pick/favorites')
          } else {
            return resolve(user)
          }
        } else {
          return reject(res)
        }
      })
      .catch(reject)
  })
}

export const sendPin = data => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(makeUrl('sendPin'), options(makeFormData(data)))
      .then(res => res.json())
      .then(res => {
        resolve(res)
      })
      .catch(reject)
  })
}

export const sendLocations = locations => dispatch => {
  const user_id = localStorage.getItem('user_id')
  locations = locations.map(x => {
    x.state = x.administrative_area_level_1
    x.city = x.locality
    x.zip_code = x.postal_code
    x.user_id = user_id
    delete x.administrative_area_level_1
    delete x.locality
    delete x.postal_code
    return x
  })
  return new Promise((resolve, reject) => {
    Promise.all(
      locations.map(x => {
        return fetch(makeUrl('locations'), options(makeFormData(x)))
      })
    )
      .then(resolve)
      .catch(reject)
  })
}

export const getRestaurants = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(
      'https://staging.bidr.co/api/chownow?key=ce52728f580499be6d00e2acbe8b996f',
      options(
        makeFormData({
          endpoint:
            'restaurant?pf=1&u_lat=32.8073318&u_lon=-79.87923089999998&origin_lat=32.8073318&origin_lon=-79.87923089999998'
        })
      )
    )
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}

export const getRestaurant = id => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(
      'https://staging.bidr.co/api/chownow?key=ce52728f580499be6d00e2acbe8b996f',
      options(
        makeFormData({
          endpoint: 'restaurant/' + id
        })
      )
    )
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}

export const getMenu = id => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(
      'https://staging.bidr.co/api/chownow?key=ce52728f580499be6d00e2acbe8b996f',
      options(
        makeFormData({
          endpoint: 'restaurant/' + id + '/menu'
        })
      )
    )
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}

export const setRestaurants = restautants => dispatch => {
  return new Promise((resolve, reject) => {
    const form_data = new FormData()
    restautants.map((x, i) => {
      const keys = Object.keys(x)
      keys.map(y => {
        form_data.append(y + '[' + i + ']', x[y])
      })
    })
    fetch(makeUrl('restaurants'), options(form_data))
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}

export const getUser = id => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(makeUrl('users/' + id), getOptions())
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}

export const saveFavorites = (
  menu_id,
  restaurant_id,
  name,
  items
) => dispatch => {
  const data = {
    user_id: localStorage.getItem('user_id'),
    name,
    order_data: JSON.stringify({
      items,
      menu_id,
      restaurant_id,
      name
    })
  }
  return new Promise((resolve, reject) => {
    fetch(makeUrl('favorites'), options(makeFormData(data)))
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}

export const magicLink = magic_link => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(makeUrl('magic_link'), options(makeFormData({ magic_link })))
      .then(res => res.json())
      .then(resolve)
      .catch(reject)
  })
}
