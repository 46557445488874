import React, { Component, Fragment } from 'react'
import { getRestaurant, getMenu, saveFavorites } from '../../db'
import { connect } from 'react-redux'
import {
  LinearProgress,
  Typography,
  Button,
  Popover,
  TextField,
  Divider
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import { withStyles } from '@material-ui/styles'
import styles from './styles'

class Restaurant extends Component {
  state = {
    restaurant: null,
    menu: null,
    picks: [],
    anchorEl: null
  }
  componentDidMount() {
    const params = this.props.match.params
    if (params && params.restaurant_id) {
      this.props
        .dispatch(getRestaurant(params.restaurant_id))
        .then(res => this.setState({ restaurant: res }))
        .catch(console.log)
      this.props
        .dispatch(getMenu(params.restaurant_id))
        .then(res => this.setState({ menu: res }))
        .catch(console.log)
    }
  }

  selectItem = item => {
    if (
      !item.modifer_categories ||
      (item.modifer_categories && item.modifer_categories.length === 0)
    ) {
      if (this.state.picks.filter(x => x.id === item.id).length === 0) {
        this.setState({ picks: this.state.picks.concat(item) })
      } else {
        this.setState({ picks: this.state.picks.filter(x => x.id !== item.id) })
      }
    } else {
      console.log('open Modal')
    }
  }

  submit = e => {
    const items = this.state.picks
    const menu_id = this.state.menu.id
    const restaurant_id = this.props.match.params.restaurant_id
    const name = document.getElementById('favorite-name').value
    this.props
      .dispatch(saveFavorites(menu_id, restaurant_id, name, items))
      .then(e => {
        if (localStorage.getItem('back_to_pick')) {
          localStorage.removeItem('back_to_pick')
          window.location.href = '/pick/favorites'
        } else {
          window.location.href = '/'
        }
      })
      .catch(console.log)
  }

  openName = e => {
    this.setState({ anchorEl: e.target })
  }

  render() {
    const { restaurant, menu, picks, anchorEl } = this.state
    const { classes } = this.props
    console.log(this.state)
    return restaurant &&
      Object.keys(restaurant).length > 0 &&
      menu &&
      Object.keys(menu).length > 0 ? (
      <div style={{ margin: '16px' }}>
        <Typography variant='h4'>{restaurant.name}</Typography>
        <br />
        <br />
        <div style={{ marginBottom: '80px' }}>
          {menu.menu_categories.map(category => {
            return (
              <div key={category.id} style={{ marginBottom: '16px' }}>
                <Typography
                  variant='h6'
                  color='secondary'
                  style={{ textDecoration: 'underline', marginBottom: '8px' }}
                >
                  {category.name}
                </Typography>
                {category.items
                  .filter(x => !x.meta_item_details)
                  .map(item => {
                    const picked =
                      picks.filter(x => x.id === item.id).length !== 0
                    return (
                      <div
                        key={item.id}
                        style={{ marginBottom: '4px' }}
                        onClick={e => {
                          this.selectItem(item)
                        }}
                      >
                        <Typography
                          variant='body1'
                          style={{ color: picked ? '#ff04a3' : 'black' }}
                        >
                          {item.name}{' '}
                          {`${item.size ? '(' + item.size + ')' : ''}`}
                        </Typography>
                        {item.description && (
                          <Fragment>
                            <Typography
                              variant='caption'
                              style={{ color: 'grey' }}
                            >
                              {item.description}
                            </Typography>
                            <br />
                          </Fragment>
                        )}
                        <Typography variant='caption' color='secondary'>
                          ${item.price}
                        </Typography>
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
        <div className={classes.bottom}>
          <Typography style={{ marginLeft: '16px' }} variant='h6'>
            {'$' + Number(picks.reduce((a, b) => a + b.price, 0)).toFixed(2)}
          </Typography>
          <Button
            className={classes.button}
            variant='contained'
            color='secondary'
            size='large'
            onClick={this.openName}
          >
            Save
          </Button>
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <div>
            <div style={{ display: 'flex' }}>
              <TextField
                placeholder='Favorite Name'
                style={{ margin: '8px', color: '#ff04a3' }}
                id='favorite-name'
                InputProps={{
                  disableUnderline: true,
                  style: { color: '#ff04a3' }
                }}
              />
              <div
                onClick={this.submit}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#ff04a3'
                }}
              >
                <Check
                  style={{ fontSize: '30px', color: 'white', padding: '8px' }}
                />
              </div>
            </div>
            <Divider />
            <div style={{ margin: '16px' }}>
              <Typography
                variant='caption'
                color='secondary'
                style={{ fontStyle: 'italics' }}
              >
                Name this favorite
              </Typography>
            </div>
          </div>
        </Popover>
      </div>
    ) : (
      <LinearProgress color='secondary' />
    )
  }
}

const mapStateToProps = state => {
  return state
}

const connector = connect(mapStateToProps)

export default connector(withStyles(styles)(Restaurant))
