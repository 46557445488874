import React from 'react'
import { AppBar, Typography } from '@material-ui/core'

function Header() {
  return (
    <AppBar style={{ position: 'relative', textAlign: 'center' }}>
      <Typography variant='h4' tag='h1' style={{ margin: '8px' }}>
        Lunch Club
      </Typography>
    </AppBar>
  )
}

export default Header
